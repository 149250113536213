import { Link, graphql } from 'gatsby';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';

const GalleryTabs = ({ module }) => {
  const imageFixLimit = 4;
  const [gallery, setGallery] = useState([]);
  const [sliderGallery, setsliderGallery] = useState([]);
  const [tabOption, setTabOption] = useState('all');
  const [imageLimit, setImageLimit] = useState(imageFixLimit);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setisOpen] = useState(false);
  const [isVideoType, setisVideoType] = useState('ImageVideo');
  const [videoUrl, setVideoUrl] = useState(false);

  useEffect(() => {
    let temp_gallery = [];
    let all_gallery = [];
    let t = 0;
    let v = 0;
    for (var i = 0; i < module.galleryTabs.length; i++) {
      for (var j = 0; j < module.galleryTabs[i].galleries.length; j++) {
        if (
          isVideoType === 'ImageVideo' &&
          (tabOption == module.galleryTabs[i].heading || tabOption == 'all')
        ) {
          all_gallery[v] = {
            image: module.galleryTabs[i].galleries[j].image,
            video: module.galleryTabs[i].galleries[j].video,
            heading: module.galleryTabs[i].heading,
          };
          v++;
          if (temp_gallery.length < imageLimit) {
            temp_gallery[t] = {
              image: module.galleryTabs[i].galleries[j].image,
              video: module.galleryTabs[i].galleries[j].video,
              heading: module.galleryTabs[i].heading,
            };
            t++;
          } else {
            break;
          }
        } else if (
          isVideoType === 'Image' &&
          (tabOption == module.galleryTabs[i].heading || tabOption == 'all')
        ) {
          if (
            module.galleryTabs[i].galleries[j].image &&
            module.galleryTabs[i].galleries[j].video == null
          ) {
            all_gallery[v] = {
              image: module.galleryTabs[i].galleries[j].image,
              video: module.galleryTabs[i].galleries[j].video,
              heading: module.galleryTabs[i].heading,
            };
            v++;
            if (temp_gallery.length < imageLimit) {
              temp_gallery[t] = {
                image: module.galleryTabs[i].galleries[j].image,
                video: module.galleryTabs[i].galleries[j].video,
                heading: module.galleryTabs[i].heading,
              };
              t++;
            } else {
              break;
            }
          }
        } else if (
          isVideoType === 'Video' &&
          (tabOption == module.galleryTabs[i].heading || tabOption == 'all')
        ) {
          if (
            module.galleryTabs[i].galleries[j].image &&
            module.galleryTabs[i].galleries[j].video
          ) {
            all_gallery[v] = {
              image: module.galleryTabs[i].galleries[j].image,
              video: module.galleryTabs[i].galleries[j].video,
              heading: module.galleryTabs[i].heading,
            };
            v++;
            if (temp_gallery.length < imageLimit) {
              temp_gallery[t] = {
                image: module.galleryTabs[i].galleries[j].image,
                video: module.galleryTabs[i].galleries[j].video,
                heading: module.galleryTabs[i].heading,
              };
              t++;
            } else {
              break;
            }
          }
        }
      }
    }
    setsliderGallery(() => [...all_gallery]);
    setGallery(() => [...temp_gallery]);
  }, [tabOption, imageLimit, isVideoType]);

  const customStylesVideo = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#FBF7F6',
      border: '0',
      borderRadius: '0',
      padding: '0',
      width: '100%',
      zIndex: '99',
    },
  };

  const [isOpenVideo, setIsOpenVideo] = React.useState(false);

  function closeModalVideo() {
    document.body.classList.remove('modal-open');
    setIsOpenVideo(false);
  }

  function filterData(title) {
    let temp_gallery = [];
    let all_gallery = [];
    let t = 0;
    let v = 0;
    if (title == 'ImageVideo') {
      let temp_gallery = [];
      let all_gallery = [];
      let t = 0;
      let v = 0;
      for (var i = 0; i < module.galleryTabs.length; i++) {
        for (var j = 0; j < module.galleryTabs[i].galleries.length; j++) {
          all_gallery[v] = {
            image: module.galleryTabs[i].galleries[j].image,
            video: module.galleryTabs[i].galleries[j].video,
          };
          v++;
          if (temp_gallery.length < imageLimit) {
            temp_gallery[t] = {
              image: module.galleryTabs[i].galleries[j].image,
              video: module.galleryTabs[i].galleries[j].video,
            };
            t++;
          } else {
            break;
          }
        }
      }
    } else if (title == 'Image') {
      for (var i = 0; i < module.galleryTabs.length; i++) {
        for (var j = 0; j < module.galleryTabs[i].galleries.length; j++) {
          if (module.galleryTabs[i].galleries[j].video === '') {
            all_gallery[v] = {
              image: module.galleryTabs[i].galleries[j].image,
              video: module.galleryTabs[i].galleries[j].video,
            };
            v++;
            if (temp_gallery.length < imageLimit) {
              temp_gallery[t] = {
                image: module.galleryTabs[i].galleries[j].image,
                video: module.galleryTabs[i].galleries[j].video,
              };
              t++;
            } else {
              break;
            }
          }
        }
      }
    } else if (title == 'Video') {
      for (var i = 0; i < module.galleryTabs.length; i++) {
        for (var j = 0; j < module.galleryTabs[i].galleries.length; j++) {
          if (module.galleryTabs[i].galleries[j].video !== '') {
            all_gallery[v] = {
              image: module.galleryTabs[i].galleries[j].image,
              video: module.galleryTabs[i].galleries[j].video,
            };
            v++;
            if (temp_gallery.length < imageLimit) {
              temp_gallery[t] = {
                image: module.galleryTabs[i].galleries[j].image,
                video: module.galleryTabs[i].galleries[j].video,
              };
              t++;
            } else {
              break;
            }
          }
        }
      }
    }
    setsliderGallery(() => [...all_gallery]);
    setGallery(() => [...temp_gallery]);
  }
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal(data) {
    document.body.classList.add('modal-open');
    setIsOpen(true);
  }

  function closeModal() {
    document.body.classList.remove('modal-open');
    setIsOpen(false);
  }
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      // width: "930px",
      padding: '0',
      border: '0',
      borderRadius: '0',
    },
    overlay: {
      zIndex: '99',
    },
  };
  return (
    <>
      <section className="img-grid-section lg:py-60 py-30">
        <div className="container-fluid">
          <div className="img-grid-filter flex flex-wrap justify-between lg:pb-50 pb-25">
            <div className="imgGrid-tabs lg:mb-0 mb-20">
              <ul className="gap-10 lg:gap-20 flex flex-wrap">
                <li
                  onClick={() => {
                    setTabOption('all');
                    setImageLimit(imageFixLimit);
                    setisVideoType('ImageVideo');
                  }}
                  className={`tab-link cursor-pointer text-black2 uppercase text-15 tracking-05em ${
                    tabOption == 'all' ? 'active-tab' : ''
                  }`}
                  data-tab="all"
                >
                  {' '}
                  <a
                    to="/"
                    className="text-brown text-18 font-400 hover:text-tandark"
                  >
                    All
                  </a>{' '}
                </li>{' '}
                {module.galleryTabs.map((item, i) => {
                  return (
                    <li
                      className={`${
                        tabOption == item.heading ? 'active-tab' : ''
                      }`}
                    >
                      {' '}
                      <a
                        onClick={() => {
                          setTabOption(item.heading);
                          setImageLimit(imageFixLimit);
                          setisVideoType('ImageVideo');
                        }}
                        className={`text-brown cursor-pointer text-18 font-400 hover:text-tandark ${
                          tabOption == item.heading ? 'tab-current' : ''
                        }`}
                        data-tab={item.heading}
                      >
                        {item.heading}
                      </a>{' '}
                    </li>
                  );
                })}{' '}
              </ul>
            </div>
            <div
              className="tab-filter"
              onChange={(e) => {
                setisVideoType(e.target.value);
              }}
            >
              <select
                name=""
                id=""
                onChange={(e) => {
                  setImageLimit(imageFixLimit);
                  filterData(e.target.value);
                }}
              >
                <option
                  value="ImageVideo"
                  selected={isVideoType === 'ImageVideo' ? 'selected' : ''}
                >
                  {' '}
                  Image & Video{' '}
                </option>
                <option
                  value="Image"
                  selected={isVideoType === 'image' ? 'selected' : ''}
                >
                  {' '}
                  Image{' '}
                </option>
                <option
                  value="Video"
                  selected={isVideoType === 'video' ? 'selected' : ''}
                >
                  {' '}
                  Video{' '}
                </option>
              </select>
            </div>
          </div>
          <div className="flex flex-wrap space-between lg:mx-minus15 gap-y-30 lg:gap-y-30">
            {gallery.map((item, i) => {
              return (
                <div className="lg:w-6/12 w-full">
                  <div className="image-wrapper lg:mx-15">
                    {item ? (
                      <>
                        <div
                          onClick={() => {
                            setPhotoIndex(i);
                            setisOpen(true);
                          }}
                        >
                          <GatsbyImage
                            image={getImage(item.image)}
                            alt={item.title}
                            className="w-100 h-100pr object-cover"
                          ></GatsbyImage>
                        </div>
                      </>
                    ) : (
                      <StaticImage
                        src="../../images/placeholder.jpg"
                        alt=""
                      ></StaticImage>
                    )}
                    {item.video !== null && (
                      <div className="play-video">
                        <button
                          onClick={() => {
                            setIsOpenVideo(true);
                            setVideoUrl(item.video);
                            document.body.classList.add('modal-open');
                          }}
                          className="inline-block play-icon"
                        >
                          <img src="../images/play.svg" alt="" />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="text-center mt-40 lg:mt-100">
            {imageLimit < sliderGallery.length && (
              <a
                onClick={() => {
                  setImageLimit((imageLimit) => imageLimit + imageFixLimit);
                }}
                className="button button-tan btn greenOutlinebtn smallBtn"
              >
                load More
              </a>
            )}
          </div>
        </div>
      </section>
      {isOpenVideo && (
        <Modal
          isOpen={isOpenVideo}
          onRequestClose={closeModalVideo}
          style={customStylesVideo}
        >
          <div className="video-modal-popup">
            <button onClick={closeModalVideo} className="pr-30 pt-30">
              <img src="/images/modal-close.svg" alt="" />
            </button>
            <div className="modal-video">
              <ReactPlayer
                className="modal-banner-video"
                loop={true}
                playing={true}
                styleVideo={customStylesVideo}
                controls={true}
                muted={true}
                url={videoUrl}
              />
            </div>
          </div>
        </Modal>
      )}

      {isOpen && (
        <Lightbox
          mainSrc={sliderGallery[photoIndex].image.mediaItemUrl}
          nextSrc={sliderGallery[(photoIndex + 1) % sliderGallery.length]}
          onImageLoadError={() => {}}
          imageLoadErrorMessage={() => {}}
          prevSrc={
            sliderGallery[
              (photoIndex + sliderGallery.length - 1) % sliderGallery.length
            ]
          }
          onCloseRequest={() => setisOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex) =>
                (photoIndex + sliderGallery.length - 1) % sliderGallery.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex(
              (photoIndex) => (photoIndex + 1) % sliderGallery.length
            )
          }
        />
      )}
    </>
  );
};

export default GalleryTabs;
export const GalleryTabsFragment = graphql`
  fragment GalleryTabsFragment on WpPage_Acfpagecontent_PageContent_GalleryTabs {
    id
    heading
    fieldGroupName
    extraClass
    galleryTabs {
      heading
      galleries {
        video
        image {
          altText
          uri
          title
          mediaItemUrl
          gatsbyImage(width: 1000, formats: WEBP, placeholder: BLURRED)
        }
      }
    }
  }
`;
