import { Link, graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import parse from 'html-react-parser';
import React from 'react';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';

const HomeBanner = ({ module, i }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal(data) {
    document.body.classList.add('video-modal-open');
    setIsOpen(true);
  }

  function closeModal() {
    document.body.classList.remove('video-modal-open');
    setIsOpen(false);
  }

  const preHeadings = useStaticQuery(graphql`
    query {
      wp {
        fluxDnaSettings {
          fluxDna {
            taglines {
              tagline
              fieldGroupName
            }
          }
        }
      }
    }
  `);
  const preHeadingsList = preHeadings.wp.fluxDnaSettings.fluxDna;
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '0',
      border: '0',
      borderRadius: '0',
    },
    overlay: {
      zIndex: '99',
    },
  };

  return (
    <>
      <section
        className={`hero-section ${
          module.extraClass === '' ? '' : module.extraClass
        }`}
      >
        <div className="h-100 w-100 min-h-700 relative">
          {module.backgroundVideo ? (
            <>
              <video
                preload="auto"
                poster={module.backgroundImage.mediaItemUrl}
                playsInline="playsinline"
                autoPlay="autoplay"
                muted="muted"
                loop="loop"
                style={{ width: `100%`, objectFit: `cover`, height: `100%` }}
              >
                <source src={module.backgroundVideo} type="video/mp4" />
              </video>
              <div className="absolute  left-0 right-0 bottom-50 inline-block align-middle items-center justify-center p-15">
                <div className="text-center translate-y-47% content-text">
                  <h1
                    className={`text-white mb-15 mx-auto ${
                      module.headingClass === '' ? '' : module.headingClass
                    }`}
                  >
                    {module.heading}
                  </h1>
                  {module.descriptions ? <p>{module.descriptions}</p> : <p></p>}
                  {module.fullVideo ? (
                    <button
                      className="inline-block play-icon"
                      onClick={openModal}
                    >
                      <img src="../images/play-icon.svg" />
                    </button>
                  ) : (
                    <div></div>
                  )}
                  <Modal
                    isOpen={modalIsOpen}
                    style={customStyles}
                    onRequestClose={closeModal}
                    contentLabel="Example Modal"
                  >
                    <div className="video-modal-popup">
                      <button onClick={closeModal} className="pr-30 pt-30">
                        <img src="/images/modal-close.svg" />
                      </button>
                      <div className="flex flex-wrap items-center">
                        <div className="lg:w-12/12 w-full h-full">
                          <div className="modal-video">
                            <div className="modal-banner-video">
                              <ReactPlayer
                                className="modal-banner-video"
                                loop={true}
                                playing={true}
                                controls={true}
                                muted={true}
                                url={module.fullVideo}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
                {/* Removed: Issue: https://github.com/fluxfc/site-jock-gatsby/issues/26 */}
                <div className="banner-bottom-menu lg:pt-250 pt-150 text-white">
                  <div className="flex uppercase text-13 w-100 flex-1 justify-center gap-25 ">
                    {/* {preHeadingsList.taglines.map((item, i) => {
                      return <h6>{item.tagline}</h6>;
                    })} */}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <GatsbyImage
                image={getImage(module.backgroundImage)}
                alt={module.backgroundImage.altText}
                className="h-100 w-100 object-cover"
              ></GatsbyImage>
              <div className="absolute inline-block left-0 right-0 bottom-50  align-middle items-center justify-center p-15">
                <div className="text-center  content-text">
                  <h1
                    className={`text-white mb-15 mx-auto ${
                      module.headingClass === '' ? '' : module.headingClass
                    }`}
                  >
                    {module.heading}
                  </h1>
                  {module.descriptions ? <p>{module.descriptions}</p> : <p></p>}
                  {module.fullVideo ? (
                    <button
                      className="inline-block play-icon"
                      onClick={openModal}
                    >
                      <img src="../images/play-icon.svg" />
                    </button>
                  ) : (
                    <div></div>
                  )}
                  <Modal
                    isOpen={modalIsOpen}
                    style={customStyles}
                    onRequestClose={closeModal}
                    contentLabel="Example Modal"
                  >
                    <div className="video-modal-popup">
                      <button onClick={closeModal} className="pr-30 pt-30">
                        <img src="/images/modal-close.svg" />
                      </button>
                      <div className="flex flex-wrap items-center">
                        <div className="lg:w-12/12 w-full h-full">
                          <div className="modal-video">
                            <div className="modal-banner-video">
                              <iframe
                                src={module.fullVideo}
                                frameborder="0"
                                allowfullscreen
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              ></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
                {/* Removed "UNTAMED UNTOUCHED UNFORGETTABLE" subheading */}
                {/* Issue: https://github.com/fluxfc/site-jock-gatsby/issues/26 */}
                <div className="banner-bottom-menu text-white">
                  <div className="flex uppercase text-13 w-100 flex-1 justify-center gap-25 lg:pt-150 pt-80">
                    {/* {preHeadingsList.taglines.map((item, i) => {
                      return <h6>{item.tagline}</h6>;
                    })} */}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default HomeBanner;
export const HomeBannerFragment = graphql`
  fragment HomeBannerFragment on WpPage_Acfpagecontent_PageContent_HomeBanner {
    # content
    id
    heading
    descriptions
    fullVideo
    fieldGroupName
    backgroundImage {
      altText
      uri
      mediaItemUrl
      gatsbyImage(
        width: 1000
        formats: WEBP
        layout: FULL_WIDTH
        placeholder: BLURRED
      )
    }
    button {
      target
      title
      url
    }
    extraClass
    headingClass
    backgroundVideo
  }
`;
