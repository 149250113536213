import React from "react";
import { graphql } from "gatsby";
import HomeBanner from "./HomeBanner";
import GeneralContent from "./GeneralContent";
import ImageContent from "./ImageContent";
import ContentBox from "./ContentBox";
import AccommodationListing from "./AccommodationListing";
import FullImageSlider from "./FullImageSlider";
import ExploreBlog from "./ExploreBlog";
import TestimonialSlider from "./TestimonialSlider";
import ExperiencesListing from "./ExperiencesListing";
import IconBox from "./IconBox";
import InstagramFeed from "./InstagramFeed";
import AfterBannerDescription from "./AfterBannerDescription";
import BlogSlider from "./BlogSlider";
import FullWidthImage from "./FullWidthImage";
import OffersListing from "./OfferListing";
import ContactInfo from "./ContactInfo";
import ImageBox from "./ImageBox";
import AccordionList from "./AccordionList";
import TransferRates from "./TransferRates";
import ExploreCard from "./ExploreCard";
import SubBanner from "./SubBanner";
import GalleryTabs from "./GalleryTabs";
import RateSection from "./RateSection";
import TermsConditions from "./TermsAndCondition";
import BlogCard from "./BlogCard";
import ContactInfoRate from "./ContactInfoRate";
import PressFeature from "./PressFeature";
import AccommodationSliderList from "./AccommodationSliderList";
import SimpleContent from "./SimpleContent";
import ExploreBlogLimit from "./ExploreBlogLimit"
import Jobs from "./Jobs";

const Modules = (props) => {
  const modules = props.modules.pageContent;
  const components = {
    HomeBanner,
    GeneralContent,
    ImageContent,
    AccommodationListing,
    FullImageSlider,
    ExploreBlog,
    TestimonialSlider,
    ExperiencesListing,
    IconBox,
    ContentBox,
    InstagramFeed,
    AfterBannerDescription,
    BlogSlider,
    FullWidthImage,
    OffersListing,
    ContactInfo,
    ImageBox,
    AccordionList,
    TransferRates,
    ExploreCard,
    SubBanner,
    GalleryTabs,
    RateSection,
    TermsConditions,
    BlogCard,
    ContactInfoRate,
    PressFeature,
    AccommodationSliderList,
    SimpleContent,
    ExploreBlogLimit,
    Jobs
  };
  return (
    <>
      {modules
        ? modules.map((module, i) => {
            const moduleName = module.__typename.replace(
              "WpPage_Acfpagecontent_PageContent_",
              ""
            );
            return (
              components[moduleName] &&
              React.createElement(components[moduleName], { key: i, module, i })
            );
          })
        : null}
    </>
  );
};

export default Modules;

export const ModulesFragment = graphql`
  fragment ModulesFragment on WpPage {
    AcfPageContent {
      pageContent {
        __typename
        ...HomeBannerFragment
        ...GeneralContentFragment
        ...FullImageSliderFragment
        ...ImageContentFragment
        ...ExploreBlogFragment
        ...ExploreBlogLimitFragment
        ...ExperiencesListingFragment
        ...IconBoxFragment
        ...InstagramFeedFragment
        ...AfterBannerDescriptionFragment
        ...BlogSliderFragment
        ...ContentBoxFragment
        ...FullWidthImageFragment
        ...ContactInfoFragment
        ...ImageBoxFragment
        ...AccordionListFragment
        ...TransferRatesFragment
        ...ExploreCardFragment
        ...SubBannerFragment
        ...GalleryTabsFragment
        ...RateSectionFragment
        ...TermsAndConditionFragment
        ...BlogCardFragment
        ...ContactInfoRateFragment
        ...PressFeatureFragment
        ...AccommodationSliderListFragment
        ...SimpleContentFragment
        ...JobFragment
      }
    }
  }
`;
