import React from "react";
import parse from "html-react-parser";
import { Link, graphql } from "gatsby";
import { BlogListingData } from "../Modules/BlogListingData";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

const ExploreBlog = ({ module, i }) => {
  const blog_data = BlogListingData();
  const blog = blog_data.posts.edges;
  return (
    <>
      <section className="blog-card lg:py-60 py-30">
        <div className="container-fluid">
          <div className="title-green text-center fade-ani">
            <h2 className="mb-10">{module.heading}</h2>
            <div className="max-w-620 mx-auto">
              {parse(module.descriptions)}
            </div>
            <Link className="mt-30 green-btn" to={module.button.url}>
              {module.button.title}
            </Link>
          </div>
          <div className='blog-card-grid mt-30 lg:mt-50 "'>
            <div className="flex flex-wrap md:flex-nowrap gap-30 md:gap-0 mx-minus15">
              {blog.map((item, i) => {
                return (
                  <div className="lg:w-4/12 md:w-4/12 w-full px-15 ">
                    <div className="card-wrapper ">
                      <div className="">
                        <div className="image-wrapper mb-20 lg:mb-40">
                          {item.node.featuredImage ? (
                            <Link to={item.node.uri}>
                              <GatsbyImage
                                image={getImage(item.node.featuredImage.node)}
                                alt={item.node.featuredImage.node.altText}
                              ></GatsbyImage>
                            </Link>
                          ) : (
                            <StaticImage
                              src="../../images/placeholder.jpg"
                              alt=""
                            ></StaticImage>
                          )}
                        </div>
                        <div className="fade-ani">
                          <div className="title-black mb-15">
                            <Link to={item.node.uri}>
                              <h4>{item.node.title}</h4>
                            </Link>
                          </div>
                          <div className="content mb-30 max-w-85pr">
                            {item.node.excerpt && parse(item.node.excerpt)}
                          </div>
                          <Link className="green-btn" to={item.node.uri}>
                            Explore
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ExploreBlog;
export const ExploreBlogFragment = graphql`
  fragment ExploreBlogFragment on WpPage_Acfpagecontent_PageContent_ExploreBlog {
    # content
    id
    heading
    fieldGroupName
    extraClass
    descriptions
    button {
      target
      title
      url
    }
  }
`;
