import React from "react";
import parse from "html-react-parser";
import { Link, graphql } from "gatsby";

const SimpleContent = ({ module }) => {
  return (
    <>
      <section className={`zigzag lg:py-60 py-30 common-content careers ${
          module.extraClass ? module.extraClass : ""
        }`}>
        <div className="container-fluid">
          <div className="flex flex-wrap justify-between">
            <div className="w-full m-auto fade-ani">
              <div className="title-green mb-20 fade-ani">
                <h2>
                  {module.heading}
                </h2>
              </div>
              <div className="content">
                {module.descriptions && parse(module.descriptions)}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SimpleContent;
export const SimpleContentFragment = graphql`
  fragment SimpleContentFragment on WpPage_Acfpagecontent_PageContent_SimpleContent {
    # content
    id
    heading
    fieldGroupName
    extraClass
    descriptions
  }
`;
