import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import parse from "html-react-parser";

const RateSection = ({ module, i }) => {
  const [tabOption, setTabOption] = useState(module.rate[0].year);
  return (
    <>
      <section className='img-grid-section lg:py-60 py-30'>
        <div className='container-fluid'>
        {module.ratesPdf && (
            <div className="flex justify-center">
              <a class="btn greenOutlinebtn smallBtn w-auto mb-20" href={module.ratesPdf.url} target={module.ratesPdf.target}>{module.ratesPdf.title}</a>
            </div>
          )}
          <ul className="tabs flex space-x-5 justify-center pb-60 lg:pb-100">
            {module.rate.map((item, i) => {
              return (
                <li
                  onClick={() => {
                    setTabOption(item.year);
                  }}
                  className={`tab-link cursor-pointer text-brown capitalize font-400 text-18 ${tabOption === item.year ? "tab-current" : ""
                    }`}
                  data-tab={item.year}
                >
                  {item.year}
                </li>
              );
            })}
          </ul>
          {module.rate.map((item, i) => {
            return (
              <div
                id="current-year"
                className={`tab-content tab-current  ${tabOption === item.year ? "tab-current" : "hidden"
                  }`}
              >
                <div className='flex flex-wrap justify-between lg:mx-minus15'>
                  {item.rate.map((item1, i) => {
                    return (
                      <div className='lg:w-5/12 w-full rates-wrap' >
                        <div className='wrapper lg:mx-15 mb-30 lg:mb-0'>
                          <div className='fade-ani'>
                            <div className='title-black mb-20'>
                              <h3>{item1.title}</h3>
                            </div>
                            <div className='content max-w-484'>
                              {item1.shortDescription && parse(item1.shortDescription)}
                            </div>
                            {item1.link && (
                              <Link to={item1.link.url} className="mt-30 green-btn">
                                {item1.link.title}
                              </Link>
                            )}
                            <div className='pricing-table mt-30'>
                              <ul>
                                {item1.ratesTable.map((item2, i) => {
                                  return (
                                    <li className="flex justify-between h-auto" key={i}>
                                      <div className="s-heading">
                                        <h5>{item2.title}</h5>
                                      </div>
                                      <div className="s-price">
                                        {parse(item2.rate)}
                                      </div>
                                      <div className="s-price">
                                        {parse(item2.rateTwo)}
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                              <Link
                                to={item1.booking.url}
                                className="btn mt-30 dark-btn cursor-pointer uppercase font-400 tracking-006em large-btn"
                              >
                                {item1.booking.title}
                              </Link>

                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default RateSection;

export const RateSectionFragment = graphql`
  fragment RateSectionFragment on WpPage_Acfpagecontent_PageContent_RateSection {
    # content
    fieldGroupName
    title
    ratesPdf {
      url
      target
      title
    }
    rate {
      year
      fieldGroupName
      rate {
        title
        shortDescription
        rateSheet {
          altText
          uri
          title
        }
        ratesTable {
          title
          rate
          rateTwo
          fieldGroupName
        }
        link {
          url
          title
          target
        }
        booking {
          target
          title
          url
        }
      }
    }
    description
    extraClass
  }
`;