import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import {
  Accordion, 
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const AccordionList = ({ module, i }) => {
  return (
    <>
      <section className={`faq-section py-30 lg:py-60 ${
          module.extraClass === "" ? "" : module.extraClass
          }`}>
          <div className="container-fluid">
            {module.heading &&
              <div className="text-center title-black mb-60 lg:mb-100"> 
                  <h2 className="mb-5">{module.heading}</h2>
                  {module.descriptions}
              </div>
            }
              <div className="accordian-section">
                  <div className="lg:w-8/12 w-full mx-auto">
                    <Accordion allowZeroExpanded={true}>
                      {module.accordions.map((item, index) => (
                        <AccordionItem>
                          <AccordionItemHeading className="accordion">
                            <AccordionItemButton>{item.title}</AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel className="fade-ani">
                            <p>{parse(item.content)}</p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </div>
              </div>
          </div>  
      </section>
    </>
  );
};

export default AccordionList;
export const AccordionListFragment = graphql`
  fragment AccordionListFragment on WpPage_Acfpagecontent_PageContent_AccordionList {
    # content
    id
    heading
    fieldGroupName
    extraClass
    descriptions
    accordions {
      title
      fieldGroupName
      content
    }
  }
`;
