import { graphql, useStaticQuery } from "gatsby";

export const BlogListingDataLimit = () => {
  const data = useStaticQuery(graphql/* GraphQL */ `
    query WpBlogsLimit {
      posts: allWpPost(sort: { fields: [id], order: [ASC] },limit: 3)  {
        edges {
          node {
           id
            title
            uri
            excerpt
            featuredImage {
              node {
                mediaItemUrl
                altText
                gatsbyImage(width: 1000, formats: WEBP, placeholder: BLURRED)
                title
                uri
              }
            }
          }
        }
      }
    }
  `);
  return data;
};
