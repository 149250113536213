import React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/zoom';
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { Navigation, Pagination, EffectFade } from "swiper";
import ReactTooltip from 'react-tooltip';
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";
import { AccommodationsData } from "../Modules/AccommodationsData";



const AccommodationSliderList = ({ module, i }) => {
  const data = AccommodationsData();
  const swiper_data = data.allWpAccommodation.edges;

  return (
    <>
    {swiper_data.map((item, i) => {
      return (
        <section className={`zigzag lg:py-60 py-30 ${
            module.extraClass === "" ? "" : module.extraClass
            }`}>
          <div className="container-fluid">
            <div className="flex flex-wrap items-center">

              <div className={`lg:w-6/12 w-full ${
                  module.imagePosition === "right" ? "lg:order-2" : ""
                }`}>
                  <div className="zigzag-content lg:max-w-450 lg:mx-auto py-30 lg:px-19 fade-ani">
                    <span className="text-greenColor text-12 capitalize leading-12 font-400">Accommodation</span>
                    <div className="title-black mb-30 mt-30">
                      <h3>{item.node.title}</h3>
                    </div>
                    <div className="content">
                      {item.node && parse(item.node.excerpt)}
                      <div className='tooltip-icon flex space-x-5 py-10 mt-20'>
                        {item.node.roomsContent.accommodationFeatures.length > 0 &&
                          item.node.roomsContent.accommodationFeatures.map((itemaf, k) => {
                            return (
                              <Tooltip
                                placement="topLeft"
                                showArrow={false}
                                overlayInnerStyle={{
                                  backgroundColor: "#787F3D",
                                  color: "#fff",
                                  border: "#787F3D",
                                  borderRadius: "0px",
                                  fontSize:'11px',
                                  opacity: "100",
                                }}
                                overlay={
                                  <div
                                    overlayStyle={{
                                      backgroundColor: "#787F3D",
                                    }}
                                  >
                                    {itemaf.heading}
                                  </div>
                                }
                                arrowContent={
                                  <div className="rc-tooltip-arrow-inner"></div>
                                }
                              >
                                <div>
                                  <img
                                    src={itemaf.image.mediaItemUrl}
                                    alt={itemaf.image.altText}
                                    className="max-w-[25px]"
                                  />
                                </div>
                              </Tooltip>
                              
                            );
                          })}
                      </div>
                      <span className='special-text'>{item.node.roomsContent.accommodationTagline}</span>
                    </div>
                    
                    <div className='btn-custom mt-30'>
                      {item.node.roomsContent.exploreButton &&
                        <Link to={item.node.roomsContent.exploreButton.url} className="btn greenOutlinebtn smallBtn">{item.node.roomsContent.exploreButton.title}</Link>
                      }
                      {item.node.roomsContent.bookingButton &&
                        <Link to={item.node.roomsContent.bookingButton.url} className="btn green-btn">{item.node.roomsContent.bookingButton.title}</Link>
                      }
                    </div>
                  </div>
              </div>

              <div
                className="lg:w-6/12 w-full">

                <Swiper navigation={true} modules={[Navigation]} className="imageSliderwitharrow">

                  {item.node.roomsContent.accommodationGallery.length > 0 &&
                    item.node.roomsContent.accommodationGallery.map((itemga, i) => {
                      return (
                        <SwiperSlide className='image-wrapper'>
                          <GatsbyImage
                            image={getImage(itemga.galleryImage)}
                            alt={itemga.galleryImage.altText}
                            className='w-100'
                          ></GatsbyImage>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>


              </div>

            </div>
          </div>
        </section>
      );
    })}
    </>
  );
};

export default AccommodationSliderList;
export const AccommodationSliderListFragment = graphql`
  fragment AccommodationSliderListFragment on WpPage_Acfpagecontent_PageContent_AccommodationSliderList {
    # content
    id
    imageSize
    imagePosition
    fieldGroupName
    extraClass
  }
`;
