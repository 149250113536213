import React, { useState, useEffect } from "react";
import { Link, graphql } from "gatsby";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { OfferListingData } from "./OfferListingData";
import parse from "html-react-parser";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

const OfferListing = () => {
  const roomData = OfferListingData();
  const rooms = roomData.allWpOffer.edges;

  return (
    <>
      <section className="img-grid-section lg:py-60 py-30">
        <div className="container-fluid">
          <div className="flex flex-wrap space-between lg:mx-minus15 gap-y-30 lg:gap-y-80">
            {rooms.map((item, i) => {
              return (
                <div className="lg:w-6/12 w-full">
                  <div className="wrapper lg:mx-15 mb-30 lg:mb-0">
                    <div className="image-wrapper mb-20 lg:mb-40">
                      {item.node.featuredImage ? (
                        <Link to={item.node.link}>
                          <GatsbyImage
                            image={getImage(item.node.featuredImage.node)}
                            alt={item.node.featuredImage.node.altText}
                            className="w-100 h-100pr object-cover"
                          ></GatsbyImage>
                        </Link>
                      ) : (
                        <StaticImage
                          src="../images/placeholder.jpg"
                          alt={item.node.title}
                        ></StaticImage>
                      )}
                    </div>
                    <div className="fade-ani">
                      <div className="title-black mb-20">
                        <Link to={item.node.link}>
                          <h3>{item.node.title}</h3>
                        </Link>
                      </div>
                      <div className="content max-w-484">
                        {item.node.excerpt && parse(item.node.excerpt)}
                      </div>
                      <Link to={item.node.link} className="mt-30 green-btn">
                      Explore
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default OfferListing;
export const OffersListingFragment = graphql`
  fragment OffersListingFragment on WpPage_Acfpagecontent_PageContent_OffersListing {
    # content
    id
    fieldGroupName
    extraClass
  }
`;
