import React from "react";
import { graphql, Link } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

const ImageBox = ({ module, i }) => {

  return (
    <>
      <section
        className={`blog-card lg:py-60 py-30 ${
          module.extraClass ? module.extraClass : ""
        }`}
      >
        <div className="container-fluid">
          <div className="title-green text-center fade-ani">
            <h2 className="mb-10">{module.heading}</h2>
            {parse(module.shortDescription)}
          </div>
          <div className="blog-card-grid mt-30 lg:mt-50">
            <div className="flex flex-wrap md:flex-nowrap gap-30 md:gap-0 mx-minus15">
              {module.imageBoxes.length > 0 &&
                module.imageBoxes.map((item, i) => {
                  return (
                    <div className="lg:w-4/12 md:w-4/12 w-full px-15">
                      <div className="card-wrapper ">
                        <div className="">
                          <div className="image-wrapper mb-20 lg:mb-40">
                            {item.image ? (
                              <Link
                                to={item.buttonLink.url}
                                target={item.buttonLink.target}
                              >
                                <GatsbyImage
                                  image={getImage(item.image)}
                                  alt={item.image.altText}
                                ></GatsbyImage>
                              </Link>
                            ) : (
                              <StaticImage
                                src="../images/placeholder.jpg"
                                alt=""
                              ></StaticImage>
                            )}
                          </div>
                          <div className="fade-ani">
                            <div className="title-black mb-15">
                              <Link
                                to={item.buttonLink.url}
                                target={item.buttonLink.target}
                              >
                                <h4>{item.heading}</h4>
                              </Link>
                            </div>
                            {item.descriptions && (
                              <div className="content mb-30 max-w-85pr">
                                {parse(item.descriptions)}
                              </div>
                            )}
                            <Link
                              to={item.buttonLink.url}
                              target={item.buttonLink.target}
                              className="green-btn"
                            >
                              {item.buttonLink.title}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ImageBox;
export const ImageBoxFragment = graphql`
  fragment ImageBoxFragment on WpPage_Acfpagecontent_PageContent_ImageBox {
    # content
    id
    heading
    fieldGroupName
    extraClass
    shortDescription
    imageBoxes {
      heading
      fieldGroupName
      descriptions
      buttonLink {
        url
        title
        target
      }
      image {
        altText
        mediaItemUrl
        gatsbyImage(
          width: 1000
          formats: WEBP
          layout: FULL_WIDTH
          placeholder: BLURRED
        )
      }
    }
  }
`;
