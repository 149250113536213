import React from "react";
import { graphql, Link } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

const ImageBox = ({ module, i }) => {
  return (
    <>
      <section className={`img-grid-section img-3-grid-section lg:py-120 py-60 bg-lightGreen bg-opacity-4 ${
          module.extraClass === "" ? "" : module.extraClass
          }`}>
        <div className="container-fluid">
          <div className="flex flex-wrap space-between lg:mx-minus15 gap-y-30 lg:gap-y-80">
            {module.press.length > 0 &&
              module.press.map((item, i) => {
                return (
                  <div className="lg:w-4/12 w-full">
                    <div className="wrapper lg:mx-15 mb-30 lg:mb-0">
                      <div className="image-wrapper logo-wrapper mb-20 lg:mb-40 h-250">
                        {item.pressLogo ? (
                           <Link to={item.button.url} >  <GatsbyImage
                            image={getImage(item.pressLogo)}
                            alt={item.pressLogo.altText}
                            className=""
                          ></GatsbyImage></Link>
                        ) : (
                          <StaticImage src="../images/placeholder.jpg" alt=""></StaticImage>
                        )}
                      </div>
                      <div className="fade-ani">
                        <div className="title-black mb-20">
                        <Link to={item.button.url} >  <h4>{item.pressTitle}</h4> </Link>
                        </div>
                        <div className="content max-w-484">
                          {parse(item.pressDescriptions)}
                        </div>
                        <Link to={item.button.url} className="mt-30 green-btn" target={item.button.target} >{item.button.title}</Link>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </>
  );
};

export default ImageBox;
export const PressFeatureFragment = graphql`
  fragment PressFeatureFragment on WpPage_Acfpagecontent_PageContent_PressFeature {
    # content
    id
    descriptions
    extraClass
    fieldGroupName
    heading
    preHeading
    press {
      button {
        target
        title
        url
      }
      fieldGroupName
      pressDescriptions
      pressTitle
      pressLogo {
        altText
        mediaItemUrl
        uri
        title
        gatsbyImage(
          width: 1000
          formats: WEBP
          placeholder: BLURRED
        )
      }
    }
  }
`;
