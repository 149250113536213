import React from "react";
import parse from "html-react-parser";
import { Link, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { useStaticQuery } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"

const FullWidthImage = ({ module, i }) => {
  return (
    <>
      <section className="promotion-sec lg:py-60 py-30">
        <div className="container-fluid">
          <div className="title-black text-center mb-60">
            <h2>{module.heading}</h2> </div>
          <div className="promotion-section bg-promotion-banner min-h-719" style={{ backgroundImage: `url(${(module.backgroundImage.mediaItemUrl) || "../images/sanbona.jpg"})` }}>
            <div className="flex justify-between  mdscreen:flex-wrap items-center lg:pt-200 gap-30">
              <div className="content">
                {module.image ? (
                  <GatsbyImage
                    image={getImage(module.image)}
                    alt={module.image.altText}
                  ></GatsbyImage>
                ) : (
                  <StaticImage src="../../images/placeholder.jpg" alt=""></StaticImage>
                )}
                <p className="max-w-450 mt-20 mb-40 text-white leading-20 font-400"> {parse(module.descriptions)} </p>
                {module.button && (
                  <Link to={module.button.url} className="uppercase text-10 tracking-06em leading-10 text-white flex items-center justify-center border-white border-1 w-170 h-35 py-10 gap-10 hover:bg-greenColor hover:border-greenColor font-400">{module.button.title} <img src="../images/visit.svg" /></Link>
                )}
              </div>
              <div className="image-wrapper"> <img src="../images/map.png" /> </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FullWidthImage;
export const FullWidthImageFragment = graphql`
  fragment FullWidthImageFragment on WpPage_Acfpagecontent_PageContent_FullWidthImage {
    # content
    id
    descriptions
    extraClass
    heading
    fieldGroupName
    button {
      url
      title
      target
    }
    backgroundImage {
      altText
      mediaItemUrl
      gatsbyImage(width: 1000, formats: WEBP, placeholder: BLURRED)
    }
    image {
      altText
      mediaItemUrl
      gatsbyImage(width: 1000, formats: WEBP, placeholder: BLURRED)
    }
  }
`;