import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

const TransferRates = ({ module, i }) => {
  const [tabOption, setTabOption] = useState(module.rateTableTab[0].tabName);
  return (
    <>
      <section
        className={`zigzag ${
          module.extraClass === "" ? "" : module.extraClass
        }`}
      >
        <div className="container-fluid">
          <div className="title-green text-center">
            <h2>{module.heading}</h2>
            <p className="mt-10">{module.descriptions}</p>
          </div>
          <ul className="tabs flex gap-30 mb-30 justify-center mt-25 lg:mb-100">
            {module.rateTableTab.map((item, i) => {
              return (
                <li
                  onClick={() => {
                    setTabOption(item.tabName);
                  }}
                  className={`tab-link ${
                    tabOption === item.tabName ? "tab-current" : ""
                  }`}
                  data-tab={item.tabName}
                >
                  {item.tabName}
                </li>
              );
            })}
          </ul>
          {module.rateTableTab.map((item, i) => {
            return (
              <div
                id={item.tabName}
                className={` ${
                  tabOption === item.tabName ? "tab-current" : "hidden"
                }`}
              >
                <div className="flex flex-wrap items-center">
                  <div className="lg:w-6/12 w-full">
                    <div className="image-wrapper">
                      {item.image ? (
                        <GatsbyImage
                          image={getImage(item.image)}
                          alt={item.image.altText}
                        ></GatsbyImage>
                      ) : (
                        <StaticImage
                          src="../images/placeholder.jpg"
                          alt={item.title}
                        ></StaticImage>
                      )}
                    </div>
                  </div>
                  <div className="lg:w-6/12 w-full ">
                    <div className="zigzag-content lg:max-w-450 lg:mx-auto pt-30 lg:py-30 lg:px-19 fade-ani">
                      <div className="title-black mb-30">
                        <h3>{item.title}</h3>
                      </div>
                      <div className="content">
                        {parse(item.innerDescriptions)}
                      </div>
                      {item.button && (
                        <Link
                          to={item.button.url}
                          target={item.button.target}
                          className="mt-40 green-btn"
                          download
                        >
                          {item.button.title}
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
                {item.tablePricing.map((tableItem, i) => {
                  return (
                    <div className="lg:w-11/12 w-full mx-auto mt-30 lg:mt-100">
                      <div className="transfer_rates">
                        <div className="title-black">
                          <h3>{tableItem.tableHeading}</h3>
                          {parse(tableItem.tableDescriptions)}
                        </div>
                        {parse(tableItem.tableContent)}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default TransferRates;
export const TransferRatesFragment = graphql`
  fragment TransferRatesFragment on WpPage_Acfpagecontent_PageContent_TransferRates {
    # content
    id
    rateTableTab {
      button {
        target
        title
        url
      }
      fieldGroupName
      image {
        altText
        mediaItemUrl
        uri
        gatsbyImage(width: 1000, formats: WEBP, placeholder: BLURRED)
      }
      imagePosition
      innerDescriptions
      tabName
      tablePricing {
        fieldGroupName
        tableContent
        tableDescriptions
        tableHeading
      }
      title
    }
    descriptions
    extraClass
    fieldGroupName
    heading
    id
  }
`;
