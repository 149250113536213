import React from "react";
import parse from "html-react-parser";
import { Link, graphql } from "gatsby";

const GeneralContent = ({ module }) => {
  return (
    <>
      <section className="zigzag lg:py-60 py-30">
        <div className="container-fluid">
          <div className="flex flex-wrap justify-between">
            <div className="lg:w-6/12 w-full ">
              <div className="title-green mb-20 fade-ani">
                <h2>
                  {module.heading}
                </h2>
              </div>
            </div>
            <div className="lg:w-5/12 w-full f">
              <div className="mt-10 fade-ani">
                <div className="content">
                  {parse(module.description)}
                  {module.button && module.button.url && (
                    <Link to={module.button.url} className="mt-40 green-btn">
                      {module.button.title}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GeneralContent;
export const GeneralContentFragment = graphql`
  fragment GeneralContentFragment on WpPage_Acfpagecontent_PageContent_GeneralContent {
    # content
    id
    heading
    extraClass
    description
    button {
      url
      title
      target
    }
  }
`;
