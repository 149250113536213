import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";

const AfterBannerDescription = ({ module }) => {
  return (
    <>
      <section
        className={`short-content text-center lg:py-60 py-30 mt-30 lg:mt-60 ${
          module.extraClass ? module.extraClass : ""
        }`}
      >
        <div className="container-fluid">
          <div className="w-full m-auto fade-ani">
            <div className="content">
              <div className="title-green">
                <h2>{parse(module.description)}</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AfterBannerDescription;
export const AfterBannerDescriptionFragment = graphql`
  fragment AfterBannerDescriptionFragment on WpPage_Acfpagecontent_PageContent_AfterBannerDescription {
    # content
    id
    description
    extraClass
    fieldGroupName
  }
`;
