import React from "react";
import { graphql, Link, MailTo, Callto, useStaticQuery } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"

const ContactInfoRate = ({ module, i }) => {
  const headerData = useStaticQuery(graphql`
    query {
      wp {
        fluxDnaSettings {
          fluxDna {
            socialMedia {
              url
              icon {
                altText
                uri
                mediaItemUrl
              }
            }
          }
        }
      }
    }
  `);
  const socialMediaIcon = headerData.wp.fluxDnaSettings.fluxDna;
  return (
    <>
      <section className="zigzag lg:py-60 py-30">
        <div className="container-fluid">
          <div className="flex flex-wrap items-center">

            <div className={`lg:w-6/12 w-full ${
                module.imagePosition === "left" ? "lg:order-2" : ""
              }`}>
              <div className="zigzag-content lg:max-w-484 lg:mx-auto pt-30 lg:py-30 lg:px-19 fade-ani"> 
                <span className="text-greenColor text-12 capitalize leading-12 font-400 pl-15">{module.preHeading}</span>
                <div className="title-black mb-30 mt-30 pl-15">
                  <h3>{module.heading}</h3> 
                </div>
                <div className="content">
                  <div className="contact-number mb-30 pl-15">

                    {module.telephoneNumber.length > 0 &&
                      module.telephoneNumber.map((item, i) => {
                        return (
                          <a href="tel:{item.number}">{item.title}: <span>{item.number}</span></a>

                        );
                    })}

                    {module.email.length > 0 &&
                      module.email.map((item, i) => {
                        return (
                          <a href="mailto:{item.email}" >{item.title}: <span >{item.email}</span></a>
                        );
                    })}
                  </div>
                  <div className="contact-number mb-30 pl-15">
                    {module.address.length > 0 &&
                      module.address.map((item, i) => {
                        return (
                          parse(item.officeAddress)
                        );
                    })}
                    </div>
                </div>
              </div>
            </div>

            <div
              className={`lg:w-6/12 w-full ${
                module.imagePosition === "right"
                  ? "" : ""
              }`}
            >
            <GatsbyImage
              image={getImage(module.image)}
              alt={module.image.altText}
              className='w-100'
            ></GatsbyImage>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactInfoRate;
export const ContactInfoRateFragment = graphql`
  fragment ContactInfoRateFragment on WpPage_Acfpagecontent_PageContent_ContactInfoRate {
    # content
    id
    heading
    fieldGroupName
    preHeading
    extraClass
    buttonStyle
    imageSize
    imagePosition
    imageBorderStyle
    address {
      officeAddress
      fieldGroupName
      title
    }
    button {
      title
      url
      target
    }
    email {
      fieldGroupName
      title
      email
    }
    telephoneNumber {
      title
      number
      fieldGroupName
    }
    image {
      altText
      mediaItemUrl
      gatsbyImage(width: 1000, formats: WEBP, layout: FULL_WIDTH, placeholder: BLURRED)
    }
  }
`;
