import { graphql, useStaticQuery } from "gatsby";

export const OfferListingData = () => {
  const data = useStaticQuery(graphql/* GraphQL */ `
    query WpOffer {
      allWpOffer(sort: { fields: [menuOrder], order: [ASC] }) {
        edges {
          node {
            id
            title
            menuOrder
            excerpt
            link
            featuredImage {
              node {
                altText
                description
                mediaItemUrl
                gatsbyImage(width: 1000, formats: WEBP, layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `);
  return data;
};
