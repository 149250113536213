import React, { useState, useEffect } from "react";
import { Link, graphql } from "gatsby";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { BlogListingData } from "./BlogListingData";
import parse from "html-react-parser";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

const BlogCard = () => {
  const blogFixLimit = 9;
  const blogData = BlogListingData();
  const blogs = blogData.posts.edges;
  const [tabDetails, setTabDetails] = useState(blogs.slice(0, blogFixLimit));
  const [blogLimit, setBlogLimit] = useState(blogFixLimit);

  useEffect(() => {
    setTabDetails(blogs.slice(0, blogLimit));
  }, [blogLimit]);
  return (
    <>
      <section className="img-grid-section img-3-grid-section lg:py-60 py-30">
        <div className="container-fluid">
          <div className="flex flex-wrap space-between lg:mx-minus15 gap-y-30 lg:gap-y-80">
            {tabDetails.length > 0 &&
              tabDetails.map((item, i) => {
                return (
                  <div className="lg:w-4/12 w-full">
                    <div className="wrapper lg:mx-15">
                      <div className="image-wrapper mb-20 lg:mb-40  ">
                        {item.node.featuredImage ? (
                          <Link to={item.node.uri}>
                            <GatsbyImage
                              alt={item.node.featuredImage.node}
                              image={getImage(item.node.featuredImage.node)}
                            />
                          </Link>
                        ) : (
                          <StaticImage
                            src="../../images/placeholder.jpg"
                            alt=""
                          ></StaticImage>
                        )}
                      </div>
                      <div className="fade-ani">
                        <div className="title-black mb-20">
                          <Link to={item.node.uri}>
                            <h4> {item.node.title}</h4>
                          </Link>
                        </div>
                        <div className="content max-w-484">
                          {parse(item.node.excerpt)}
                        </div>
                        <Link to={item.node.uri} className="mt-30 green-btn">
                          read more
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>

          {blogLimit < blogs.length && (
            <div className="text-center mt-40 lg:mt-100">
              <a
                onClick={() => {
                  setBlogLimit((blogLimit) => blogLimit + blogFixLimit);
                }}
                className="btn greenOutlinebtn mdbtn w-auto"
              >
                Load More Blogs
              </a>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default BlogCard;
export const BlogCardFragment = graphql`
  fragment BlogCardFragment on WpPage_Acfpagecontent_PageContent_BlogCard {
    # content
    id
    fieldGroupName
  }
`;
