import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper";
import { Link } from "gatsby";

const InstagramFeed = ({ module, i }) => {
  // const [isLastImage, setisLastImage] = useState(true);
  // const data = useStaticQuery(graphql`
  //   query InstagramQuery {
  //     allInstagramContent(limit: 10) {
  //       edges {
  //         node {
  //           localImage {
  //             childImageSharp {
  //               gatsbyImageData(width: 1000, formats: WEBP)
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // `);
  // let arrayOfInstaImages = data.allInstagramContent.edges;

  // const handleNextClick = () => {
  //   const swiper = document.querySelector(".instagram-slider .contentSlider").swiper;
  //   swiper.slideNext();
  // };
  // const handlePrevClick = () => {
  //   const swiper = document.querySelector(".instagram-slider .contentSlider").swiper;
  //   if (swiper.activeIndex === 1) {
  //     setisLastImage(true);
  //   }
  //   swiper.slidePrev();
  // };
  return (
    <>
      {/* <section className="content-with-slider instagram-slider lg:py-60 py-30">
        <div className="container-fluid !pr-0">
          <div className="lg:flex items-center justify-between">
            <div className="lg:w-3/12">
              <div className="content mb-30 lg:mb-0 fade-ani">
                <div className="title-green mb-30">
                  <h2>{module.heading}</h2>
                </div>
                {parse(module.description)}
                <div className="swiper-arrow">
                  <a
                    className={`button-prev cursor-pointer ${
                      isLastImage ? "opacity-50" : ""
                    } `}
                    onClick={handlePrevClick}
                  >
                    <img src="../images/swiper-next.svg" alt="" />
                  </a>
                  <a
                    className="button-next cursor-pointe"
                    onClick={handleNextClick}
                  >
                    <img src="../images/swiper-next.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="lg:w-9/12">
              <Swiper
                slidesPerView={2.5}
                spaceBetween={30}
                speed={1000}
                loop={false}
                onSlideChange={(e) => {
                  const swiper =
                    document.querySelector(".instagram-slider .contentSlider").swiper;
                  swiper.activeIndex === 0
                    ? setisLastImage(true)
                    : setisLastImage(false);
                }}
                autoplay={{ delay: 5000 }}
                breakpoints={{
                  0: { spaceBetween: 15 },
                  768: { slidesPerView: 4, spaceBetween: 20 },
                  1024: { spaceBetween: 30 },
                  2300: { slidesPerView: 2.5 },
                }}
                className="contentSlider"
              >
                {arrayOfInstaImages.length > 0 &&
                  arrayOfInstaImages.map((item, i) => {
                    return (
                      <SwiperSlide>
                        <GatsbyImage
                          image={getImage(item.node.localImage)}
                          alt=""
                        />
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default InstagramFeed;
export const InstagramFeedFragment = graphql`
  fragment InstagramFeedFragment on WpPage_Acfpagecontent_PageContent_InstagramFeed {
    # content
    id
    heading
    fieldGroupName
    extraClass
    description
  }
`;
