import React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

const ImageContent = ({ module }) => {
  return (
    <>
      <section
        className={`zigzag lg:py-60 py-30 ${
          module.extraClass === "" ? "" : module.extraClass
        }`}
      >
        <div className="container-fluid">
          <div className="flex flex-wrap items-center">
            <div
              className={`lg:w-6/12 w-full ${
                module.imagePosition === "left" ? "lg:order-2" : ""
              }`}
            >
              <div className="zigzag-content lg:max-w-450 lg:mx-auto py-30 lg:px-19 fade-ani">
                <span className="text-greenColor text-12 capitalize leading-12 font-400">
                  {module.perHeading}
                </span>
                <div className="title-black mb-30 mt-30">
                  <h3>{module.heading}</h3>
                </div>
                <div className="content">{parse(module.description)}</div>
                {module.button && (
                  <Link
                    to={module.button.url}
                    target={module.button.target}
                    className="mt-40 btn greenOutlinebtn smallBtn"
                  >
                    {module.button.title}
                  </Link>
                )}
                {module.extraButton && (
                    <Link to={module.extraButton.url} className="green-btn ml-30">
                      {module.extraButton.title}
                    </Link>
                  )}
              </div>
            </div>
            <div
              className={`lg:w-6/12 w-full ${
                module.imagePosition === "right" ? "" : ""
              }`}
            >
              <GatsbyImage
                image={getImage(module.image)}
                alt={module.image.altText}
              ></GatsbyImage>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ImageContent;
export const ImageContentFragment = graphql`
  fragment ImageContentFragment on WpPage_Acfpagecontent_PageContent_ImageContent {
    # content
    id
    perHeading
    imageSize
    imagePosition
    image {
      altText
      uri
      title
      mediaItemUrl
      gatsbyImage(
        width: 1000
        formats: WEBP
        layout: FULL_WIDTH
        placeholder: BLURRED
      )
    }
    heading
    extraClass
    description
    extraButton {
      url
      title
      target
    }
    button {
      target
      title
      url
    }
  }
`;
