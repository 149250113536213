import React, { useEffect } from "react";
import parse from "html-react-parser";
import { Link, graphql } from "gatsby";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { AccommodationsData } from "../Modules/AccommodationsData";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

const ExploreCard = ({ module, i }) => {
  const data = AccommodationsData();
  const swiper_data = data.allWpAccommodation.edges;
  return (
    <>
      <section className='img-grid-section lg:py-60 py-30'>
        <div className='container-fluid'>
            <div className='title-green text-center mb-30 lg:mb-60'>
                <h2>GPS Co-Ordinates</h2>
            </div>
          <div className='flex flex-wrap space-between lg:mx-minus15'>
          {swiper_data.map((item, i) => {
              return (
                <div className='lg:w-6/12 w-full'>
                    <div className='wrapper lg:mx-15 mb-30 lg:mb-0'>
                        <div className='image-wrapper mb-20 lg:mb-40 '>
                          {item.node.featuredImage ? (
                            <GatsbyImage
                              image={getImage(item.node.featuredImage.node)}
                              alt={item.node.featuredImage.node.altText}
                              className="w-100 h-100pr object-cover"
                            ></GatsbyImage>
                          ) : (
                            <StaticImage src="../images/placeholder.jpg" alt="" ></StaticImage>
                          )}
                        </div>
                        <div className='fade-ani'>
                        <div className='title-black mb-20'>
                          <h3>{item.node.title}</h3>
                        </div>
                        <div className='content max-w-484'>
                            <p>{item.node.roomsContent.accommodationMapDirection}</p>
                        </div>
                      </div>
                    </div>
                </div> 
              )
            })}
          </div>
      </div>
    </section>
    </>
  );
};

export default ExploreCard;
export const ExploreCardFragment = graphql`
  fragment ExploreCardFragment on WpPage_Acfpagecontent_PageContent_ExploreCard {
    # content
    id
    extraClass
  }
`;
