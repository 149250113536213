import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"

const IconBox = ({ module, i }) => {
  return (
    <>
      <section className="icon-list my-30 lg:my-60 py-60 lg:py-120 bg-lightGreen bg-opacity-4">
        <div className="container-fluid">
            <div className="title-black text-center fade-ani mb-40">
                <h2 className="mb-10">{module.heading}</h2>
                {parse(module.shortDescription)}
            </div>
            <div className="grid icon-grid">
              {module.iconBoxes.length > 0 &&
                module.iconBoxes.map((item, i) => {
                  return (
                    <div className="icon-content text-center">
                      {item.image ? (
                        <img src={item.image.mediaItemUrl} alt={item.image.altText} />
                      ) : (
                        <StaticImage src="../images/placeholder.jpg" alt=""></StaticImage>
                      )}
                        <span>{item.heading}</span>
                    </div>
                  );
              })}
            </div>
        </div>
    </section>
    </>
  );
};

export default IconBox;
export const IconBoxFragment = graphql`
  fragment IconBoxFragment on WpPage_Acfpagecontent_PageContent_IconBox {
    # content
    id
    shortDescription
    extraClass
    heading
    iconBoxes {
      descriptions
      fieldGroupName
      heading
      image {
        altText
        mediaItemUrl
        uri
      }
      buttonLink {
        title
        target
        url
      }
    }
  }
`;
