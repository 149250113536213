import React from "react";
import { Link, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import parse from "html-react-parser";

const SubBanner = ({ module, i }) => {
  return (
    <>
      <section className="hero-section inner-page-banner guest-page">
        <div className="h-100 w-100 min-h-700 relative">
          <BackgroundImage
            Tag="section"
            className="banner-img h-full bg-cover flex items-center justify-center"
            {...convertToBgImage(getImage(module.backgroundImage))}
            preserveStackingContext
          >
            <div className="banner-img h-full bg-cover flex items-center justify-center">
              <GatsbyImage
                className="banner-img h-full bg-cover flex items-center justify-center"
                image={getImage(module.backgroundImage)}
              />
            </div>
          </BackgroundImage>
          <div className="absolute top-0 left-0 right-0 bottom-0 p-15">
            <div className="text-center content-text">
              <div className="fade-ani">
                <h1 className="text-white mb-15 mx-auto">Thank You</h1>
                {parse(module.description)}
                <Link
                  to="/"
                  className="btn mt-30 dark-btn cursor-pointer uppercase font-400 tracking-006em large-btn"
                >
                  Go back to home
                </Link>
              </div> 
            </div>
          </div>
          {module.preHeadings && (
            <div className="absolute text-white left-0 right-0 bottom-50 banner-bottom-menu fade-ani">
              <ul className="flex uppercase text-13 w-100 flex-1 justify-center gap-25 ">
                {module.preHeadings.map((item, i) => {
                  return <li>{item.preHeading}</li>;
                })}
              </ul>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default SubBanner;

export const SubBannerFragment = graphql`
  fragment SubBannerFragment on WpPage_Acfpagecontent_PageContent_SubBanner {
    # content
    id
    heading
    fullVideo
    fieldGroupName
    description
    button {
      url
      title
      target
    }
    backgroundImage {
      altText
      uri
      mediaItemUrl
      gatsbyImage(
        width: 1000
        formats: WEBP
        layout: FULL_WIDTH
        placeholder: BLURRED
      )
    }
    button {
      target
      title
      url
    }
    preHeadings {
      preHeading
    }
    extraClass
    backgroundVideo
  }
`;
