import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import parse from "html-react-parser";

const TermsAndCondition = ({ module, i }) => {
  const [tabOption, setTabOption] = useState(module.termsContent[0].tabName);

  return (
    <>
      <section className='terms-condition lg:py-120 py-60 my-30 lg:my-60 bg-lightGreen bg-opacity-4'>
        <div className='container-fluid'>
          <div className='flex flex-wrap items-center  justify-around'>
            <div className='w-full'>
              <div className='zigzag-content lg:max-w-auto lg:mx-auto fade-ani'> 
                <span className='text-greenColor text-12 capitalize leading-12 font-400'>Rate</span>
                <div className='title-black mb-30 mt-30'>
                  <h3>Terms & Conditions</h3> 
                </div>
                <ul className='tabs flex flex-wrap gap-20 mb-30'>
                  {module.termsContent.map((item, i) => {
                    return (
                      <li
                        onClick={() => {
                          setTabOption(item.tabName);
                        }}
                        className={`tab-link cursor-pointer text-brown capitalize font-400 text-18 ${
                          tabOption === item.tabName ? "tab-current" : ""
                        }`}
                        data-tab={item.tabName}
                      >
                        {item.tabName}
                      </li>
                    );
                  })}
                </ul>

                <div className='General'>
                {module.termsContent.map((item, i) => {
                  return (
                    <div
                      id={item.tabName}
                      className={`tab-content tab-current ${
                        tabOption === item.tabName ? "tab-current" : "hidden"
                      }`}
                    >
                      <div className='content global-list'>
                        {parse(item.tabContent)}
                      </div>
                    </div>
                  );
                })}

                </div> 

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsAndCondition;

export const TermsAndConditionFragment = graphql`
  fragment TermsAndConditionFragment on WpPage_Acfpagecontent_PageContent_TermsConditions {
    # content
    fieldGroupName
    heading
    preHeading
    termsContent {
      fieldGroupName
      tabContent
      tabName
    }
  }
`;