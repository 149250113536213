import { graphql, useStaticQuery } from "gatsby";
export const ExperiencesListingData = () => {
  const data = useStaticQuery(graphql/* GraphQL */ `
    query WpExperience {
      allWpExperience (
        sort: {
          fields: [menuOrder]
          order: [ASC]
        }
      ) {
        edges {
          node  {
            id
            link
            title
            menuOrder
            excerpt
            content
            featuredImage {
              node {
                mediaItemUrl
                altText
                gatsbyImage(width: 1000, formats: WEBP, layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
            experienceContent {
              experiencePopupSlider {
                image {
                  altText
                  uri
                  mediaItemUrl
                  gatsbyImage(width: 1000, formats: WEBP, layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  `);
  return data;
};
