import React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Navigation, Pagination, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { AccommodationsData } from "../Modules/AccommodationsData";

const AccommodationListing = ({ module }) => {

  const data = AccommodationsData();
  const swiper_data = data.allWpAccommodation.edges;
  return (
    <>
      <section className="img-grid-section lg:py-60 py-30">
        <div className="container-fluid">
          <div className="flex flex-wrap space-between lg:mx-minus15">
            {swiper_data.map((item, i) => {
              return (
                <div className="lg:w-6/12 w-full" key={item.node.id}>
                  <div className="wrapper lg:mx-15 mb-30 lg:mb-0">
                    {item.node.featuredImage ? (
                      <Link to={item.node.link}>
                        
                        <GatsbyImage
                          image={getImage(item.node.featuredImage.node)}
                          alt={item.node.featuredImage.node.altText}
                          className="w-100 h-100pr object-cover"
                        ></GatsbyImage>
                      </Link>
                    ) : (
                      <StaticImage
                        src="../images/placeholder.jpg"
                        alt=""
                      ></StaticImage>
                    )}

                    <div className="fade-ani mt-20 lg:mt-40">
                      <div className="title-black mb-20">
                        <Link to={item.node.link}>
                          
                          <h3>{item.node.title}</h3>
                        </Link>
                      </div>
                      <div className="content max-w-484">
                        {item.node && parse(item.node.excerpt)}
                      </div>
                      <Link to={item.node.link} className="mt-30 green-btn">
                        Explore
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default AccommodationListing;
