import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import parse from "html-react-parser";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const Jobs = ({ module, i }) => {
  const [displayedAccordions, setDisplayedAccordions] = useState(3);
  const totalAccordions = module.jobPost.length;

  const showMoreAccordions = () => {
    const newDisplayedAccordions = Math.min(displayedAccordions + 3, totalAccordions);
    setDisplayedAccordions(newDisplayedAccordions);
  };

  const slicedJobPosts = module.jobPost.slice(0, displayedAccordions);

  return (
    <>
      <section
        className={`jobs  bg-white lg:pt-20 lg:pb-60 py-30 mb-60 lg:px-40 ${
          module.extraClass !== "" ? module.extraClass : ""
        }`} id="my-careers"
      >
        <div className="container m-auto py-80 bg-smokyWhite px-80">
          <div className="accordian-section">
            <Accordion allowZeroExpanded={true}>
              {slicedJobPosts.map((item, index) => (
                <div className="mb-30 lg:mb-60 max-w-[800px] mx-auto" key={index}>
                  <div className="content fade-ani">
                      <h4 className="capitalize pb-15">{item.heading}</h4>
                      <p>{parse(item.shortDescription)}</p>
                      <div className="link-btn mt-20 mb-20 uppercase  ">
                        <span></span>
                        {item.button && (
                          <a className="border-b-1 pb-10" target={item.button.target} href={item.button.url}>
                            {/* {item.button.title} */} Download Job Description
                          </a>
                        )}
                      </div>
                  </div>
                  <AccordionItem>
                    <AccordionItemHeading className="accordion pt-20">
                      <AccordionItemButton>
                        <h5>
                          Job Details
                        </h5>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="fade-ani">
                      <p>{parse(item.descriptions)}</p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              ))}
            </Accordion>
            {displayedAccordions < totalAccordions && (
              
              <div class="btn-custom lg:mt-40 mt-20 text-center">
                <a target="" class="button mt-30 green-btn" onClick={showMoreAccordions}>Load More </a>
              </div>
              
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Jobs;
export const JobFragment = graphql`
  fragment JobFragment on WpPage_Acfpagecontent_PageContent_Jobs {
    # content
    id
    extraClass
    fieldGroupName
    jobPost {
        shortDescription
        heading
        descriptions
        button {
          url
          title
          target
        }
    }
  }
`;