import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import React from 'react';
import Modal from 'react-modal';
import { EffectFade, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/zoom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ExperiencesListingData } from './ExperiencesListingData';

const ExperiencesListing = ({ module, i }) => {
  const experiencesData = ExperiencesListingData();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [url, setURL] = React.useState([]);
  const [title, setTitle] = React.useState(null);
  const [content, setContent] = React.useState(null);
  function openModal(data) {
    for (var i = 0; i < experiencesData.allWpExperience.edges.length; i++) {
      if (experiencesData.allWpExperience.edges[i].node.id === data) {
        setURL(
          experiencesData.allWpExperience.edges[i].node.experienceContent
            .experiencePopupSlider
        );
        setTitle(experiencesData.allWpExperience.edges[i].node.title);
        setContent(
          parse(experiencesData.allWpExperience.edges[i].node.content)
        );
      }
    }
    document.body.classList.add('modal-open');
    setIsOpen(true);
  }

  function closeModal() {
    document.body.classList.remove('modal-open');
    setIsOpen(false);
  }
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      // width: "930px",
      padding: '0',
      border: '0',
      borderRadius: '0',
      height: '100vh',
      width: '100%',
    },
    overlay: {
      zIndex: '99',
    },
  };

  const inquirybuttonData = useStaticQuery(graphql`
    query {
      wp {
        fluxDnaSettings {
          fluxDna {
            travellerEnquiryButton {
              url
              title
              target
            }
          }
        }
      }
    }
  `);
  const inquirybuttonDataList = inquirybuttonData.wp.fluxDnaSettings.fluxDna;

  return (
    <>
      <section
        className={`img-grid-section lg:py-60 py-30 ${
          module.extraClass === '' ? '' : module.extraClass
        }`}
      >
        <div className="container-fluid">
          <div className="flex flex-wrap space-between lg:mx-minus15 gap-y-30 lg:gap-y-80">
            {experiencesData.allWpExperience.edges.length > 0 &&
              experiencesData.allWpExperience.edges.map((item, i) => {
                return (
                  <div key={i} className="lg:w-6/12 w-full">
                    <div className="wrapper lg:mx-15 mb-30 lg:mb-0">
                      <div
                        className="image-wrapper mb-20 lg:mb-40 cursor-pointer"
                        onClick={() => {
                          openModal(item.node.id);
                        }}
                      >
                        <GatsbyImage
                          image={getImage(item.node.featuredImage.node)}
                          className="w-100 h-100pr object-cover"
                        ></GatsbyImage>
                      </div>
                      <div className="fade-ani">
                        <div
                          className="title-black mb-20 cursor-pointer"
                          onClick={() => {
                            openModal(item.node.id);
                          }}
                        >
                          <h3>{item.node.title}</h3>
                        </div>
                        <div className="content max-w-484">
                          {parse(item.node.excerpt)}
                        </div>
                        <button
                          className="mt-30 green-btn"
                          onClick={() => {
                            openModal(item.node.id);
                          }}
                        >
                          Explore
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <button
          className="modal-close absolute right-40 top-40"
          onClick={closeModal}
        >
          <img src="/images/close.svg" alt="" />
        </button>
        <div className="img-modal">
          <div className="flex flex-wrap items-center">
            <div className="lg:w-6/12 w-full h-full">
              <div className="img lg:h-100 h-418 overflow-hidden">
                <Swiper
                  navigation={true}
                  modules={[Navigation]}
                  className="imageSliderwitharrow"
                >
                  {url.length > 0 &&
                    url.map((item, i) => {
                      return (
                        <SwiperSlide className="image-wrapper">
                          <GatsbyImage
                            image={getImage(item.image)}
                          ></GatsbyImage>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </div>
            </div>
            <div className="lg:w-6/12 w-full">
              <div className="zigzag-content lg:max-w-450 lg:mx-auto py-30 px-30 lg:px-19 fade-ani">
                <span className="text-greenColor text-12 capitalize leading-12 font-400">
                  Experiences
                </span>
                <div className="title-black mb-30 mt-30">
                  <h3>{title}</h3>
                </div>
                <div className="content lg:h-55vh overflow-auto">{content}</div>
                <div className="btn-custom mt-30">
                  <Link
                    to={inquirybuttonDataList.travellerEnquiryButton.url}
                    className="btn greenOutlinebtn mdbtn"
                  >
                    Make an enquiry
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ExperiencesListing;
export const ExperiencesListingFragment = graphql`
  fragment ExperiencesListingFragment on WpPage_Acfpagecontent_PageContent_ExperiencesListing {
    # content
    id
    extraClass
    heading
  }
`;
